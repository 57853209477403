#everyone .select,
.select {
  height: 32px !important;
  width: 140px !important;
  margin-left: auto;
}

#subtitle {
  font-size: 12px;
  color: gray;
}

.everyone {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.foot {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 0.5rem;
}

a {
  text-decoration: none;
  color: black;
}
